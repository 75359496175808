import { AsyncWalkBuilder } from 'walkjs'
import { PRODUCT_QUERY } from '../queries'

export default async function replaceReferences (
  input,
  referencesResolver,
  locale
) {
  await new AsyncWalkBuilder()
    .withGlobalFilter((x) => x.val?._type === 'product_listing' || x.val?._type === 'sample_listing')
    .withSimpleCallback(async (node) => {
      const categoryId = node.val.category?._ref
      const categoryFilter = categoryId ? `&& (primaryCategory._ref == '${categoryId}' || categories[]._ref == '${categoryId}')` : ''
      referencesResolver.addQuery(
        'products',
        `*[!(_id in path('drafts.**')) && _type == "product" && active == true && product.purchase_disabled == false && site->locale == '${locale}' ${categoryFilter}] | order(_createdAt desc) [0...3]{ ${PRODUCT_QUERY} }`,
        (key, results) => {
          node.val.products = results
        })
    })
    .walk(input)
}
