import { AsyncWalkBuilder } from 'walkjs'
import find from 'lodash/find'

export default async function replaceReferences (
  input,
  referencesResolver
) {
  await new AsyncWalkBuilder()
    .withGlobalFilter((x) => x.val?._type === 'productVariant')
    .withSimpleCallback(async (node) => {
      const productId = node.val?.product?._ref
      if (productId) {
        referencesResolver.addDocument('product', productId, (key, _, results) => {
          node.val.product = find(results, doc => doc._id === productId) || null
        })
      }
    })
    .walk(input)
}
