import isArray from 'lodash/isArray'
import replaceReferences from './replaceReferences'
import replaceImages from './replaceImages'
import replaceProductVariants from './replaceProductVariants'
import replaceLatestPosts from './replaceLatestPosts'
import replaceProductList from './replaceProductList'
import replaceJournalListing from './replaceJournalListing'
import resolveTradeChildHero from './resolveTradeChildHero'
import resolveRelatedPosts from './resolveRelatedPosts'
import resolveRelatedProducts from './resolveRelatedProducts'
import resolveCareerListing from './resolveCareerListing'
import ReferencesResolver from './ReferencesResolver'
import asyncForEach from '../../helpers/asyncForEach'

const resolveObject = async (locale, data, referencesResolver) => {
  const resolvers = [
    replaceImages,
    replaceReferences,
    replaceProductVariants,
    replaceLatestPosts,
    replaceProductList,
    replaceJournalListing,
    resolveTradeChildHero,
    resolveRelatedPosts,
    resolveRelatedProducts,
    resolveCareerListing
  ]
  await asyncForEach(resolvers, async r => await r(data, referencesResolver, locale))
}

export default async function resolve (locale, data, client) {
  const referencesResolver = new ReferencesResolver(client)
  if (isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      await resolveObject(locale, data[i], referencesResolver)
    }
  } else {
    await resolveObject(locale, data, referencesResolver)
  }

  await referencesResolver.resolve()
  return data
}
