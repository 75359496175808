import { AsyncWalkBuilder } from 'walkjs'
import { JOURNALS_QUERY } from '../queries'

export default async function replaceJournalListing (
  input,
  referencesResolver,
  locale
) {
  await new AsyncWalkBuilder()
    .withGlobalFilter((x) => x.val?._type === 'journal_listing')
    .withSimpleCallback(async (node) => {
      referencesResolver.addQuery(
        'journal',
        `${JOURNALS_QUERY().replace('$locale', `"${locale}"`)} [0...3]`,
        (key, results) => {
          node.val.journals = results
        })
    })
    .walk(input)
}
