import { AsyncWalkBuilder } from 'walkjs'
import { PAGE_REFERENCE_QUERY } from '../queries'

export default async function resolveCareerListing (
  input,
  referencesResolver,
  locale
) {
  await new AsyncWalkBuilder()
    .withGlobalFilter((x) => x.val?._type === 'career_listing')
    .withSimpleCallback(async (node) => {
      if (!node.val.manualMode) {
        referencesResolver.addQuery(
          'career_listing',
          `*[!(_id in path('drafts.**')) && _type=="page" && pageType=="career" && site->locale == '${locale}'] | order(location desc) { ${PAGE_REFERENCE_QUERY} }`,
          (key, results) => {
            node.val.automaticRoles = results
          }
        )
      }
    })
    .walk(input)
}
