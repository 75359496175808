import { getClient } from './sanity.server'
import { JOURNALS_QUERY, createPageQuery, settingsQuery } from './queries'
import resolve from './resolvers/resolve'
import omitBy from 'lodash/omitBy'

export async function getAllDocuments (type) {
  return getClient()
    .fetch(`*[!(_id in path('drafts.**')) && _type == $type]{
      _id,
      "slug": slug.current,
      "locale": site->locale,
      "siteUrl": site->siteUrl,
      parent->{ title, "slug": slug.current }
    }`, { type })
}

// Fetch a static page with our global data
export async function getStaticPage (slug, locale, preview) {
  const client = getClient(preview)
  const query = `
    {
      "page": *[${!preview ? '!(_id in path(\'drafts.**\')) && ' : ''}(_type == "page" || _type == "product") && site->locale == $locale && slug.current == $slug] | order(_updatedAt desc)[0] {
        ${createPageQuery()}
      },
      "localeVariations": *[${!preview ? '!(_id in path(\'drafts.**\')) && ' : ''}(_type == "page" || _type == "product") && site->locale != $locale && slug.current == $slug] | order(_updatedAt desc) {
        title,
        "slug": slug.current,
        "locale": site->locale,
        "siteUrl": site->siteUrl,
        "parent": parent->{
          _id,
          _type,
          title,
          "slug": slug.current
        }
      },
      "settings": *[${!preview ? '!(_id in path(\'drafts.**\')) && ' : ''}_type == "site" && locale == $locale] | order(_updatedAt desc)[0] { ${settingsQuery} }
    }
  `
  const data = await getClient(preview).fetch(query, { slug, locale })

  await resolve(locale, data, client)

  return data
}

export async function getPostCategories (locale, preview) {
  const client = getClient(preview)
  return client.fetch(
    '*[!(_id in path(\'drafts.**\')) && _type == "postCategory" && site->locale == $locale] { ..., "slug": slug.current }',
    { locale }
  )
}

export async function getJournals ({ parentSlug, locale, categorySlug, page = 0, limit = 14, preview }) {
  const client = getClient(preview)

  const query = JOURNALS_QUERY(categorySlug, parentSlug)
  const totalCount = await client.fetch(`count(${query})`, omitBy({ locale, parentSlug, categorySlug }, x => !x))
  const journals = await client.fetch(`${query} [0...$end]`, omitBy({ locale, parentSlug, categorySlug, end: ((page * limit) + limit) }, x => !x))

  return {
    totalCount,
    journals,
    category: categorySlug || null,
    page,
    limit
  }
}

export async function getErrorPage (errorCode, locale, preview) {
  const client = getClient(preview)

  const query = `
  {
    "page": *[${!preview ? '!(_id in path(\'drafts.**\')) && ' : ''}_type == "errorPage" && error_code == $errorCode && site->locale == $locale] | order(_updatedAt desc)[0] {
      ${createPageQuery()}
    },
    "settings": *[${!preview ? '!(_id in path(\'drafts.**\')) && ' : ''}_type == "site" && locale == $locale] | order(_updatedAt desc)[0] { ${settingsQuery} }
  }
`
  const data = await client.fetch(query, { errorCode, locale: locale || 'en-AU' })

  await resolve(locale, data, client)

  return data
}
