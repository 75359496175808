/**
 * Server-side Sanity utilities. By having these in a separate file from the
 * utilities we use on the client side, we are able to tree-shake (remove)
 * code that is not used on the client side.
 */
import { createClient } from 'next-sanity'
import { sanityConfig } from './config'
import { isBrowser } from './helpers'

export const sanityClient = createClient({
  ...sanityConfig,
  useCdn: !process.env.SANITY_API_READ_TOKEN,
  token: process.env.SANITY_API_READ_TOKEN
})

export const sanityWritableClient = createClient({
  ...sanityConfig,
  useCdn: false,
  token: isBrowser ? null : process.env.SANITY_API_TOKEN
})

export const previewClient = createClient({
  ...sanityConfig,
  useCdn: false,
  token: isBrowser ? null : process.env.SANITY_API_TOKEN
})

export const getWritableClient = () => sanityWritableClient

export const getClient = (preview) => (preview ? previewClient : sanityClient)

export function overlayDrafts (docs) {
  const documents = docs || []
  const overlayed = documents.reduce((map, doc) => {
    if (!doc._id) {
      throw new Error('Ensure that `_id` is included in query projection')
    }

    const isDraft = doc._id.startsWith('drafts.')
    const id = isDraft ? doc._id.slice(7) : doc._id
    return isDraft || !map.has(id) ? map.set(id, doc) : map
  }, new Map())

  return Array.from(overlayed.values())
}
