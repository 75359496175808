import { AsyncWalkBuilder } from 'walkjs'
import { PAGE_REFERENCE_QUERY } from '../queries'

const QUERY = (id) => `
*[!(_id in path('drafts.**')) && _type=="page" && pageType == "post" && references('${id}')] | order(date desc) [0..1]{
  ${PAGE_REFERENCE_QUERY}
}`

export default async function resolveRelatedPosts (
  input,
  referencesResolver,
  locale
) {
  await new AsyncWalkBuilder()
    .withGlobalFilter((x) => x.val?._type === 'product_journal_posts_slice')
    .withSimpleCallback(async (node) => {
      if (!node.val.manualMode) {
        referencesResolver.addQuery('related_posts', QUERY(input.page._id), (key, results) => {
          node.val.relatedPosts = results
        })
      }
    })
    .walk(input)
}
