import { AsyncWalkBuilder } from 'walkjs'
import { PAGE_REFERENCE_QUERY } from '../queries'
import isEmpty from 'lodash/isEmpty'

const QUERY = (id, primaryCategoryRef) => `
*[!(_id in path('drafts.**')) && _type=="product" &&
  _id != '${id}' &&
  primaryCategory._ref != null &&
  primaryCategory._ref == '${primaryCategoryRef}'
][0...3]{
  ${PAGE_REFERENCE_QUERY}
}`

export default async function resolveRelatedProducts (
  input,
  referencesResolver,
  locale
) {
  await new AsyncWalkBuilder()
    .withGlobalFilter((x) => x.val?._type === 'product')
    .withSimpleCallback(async (node) => {
      if (input.page.primaryCategory && isEmpty(node.val.relatedProduct)) {
        referencesResolver.addQuery('related_products', QUERY(input.page._id, input.page.primaryCategory._id), (key, results) => {
          node.val.relatedProductsByCategory = results
        })
      }
    })
    .walk(input)
}
