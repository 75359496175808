import { AsyncWalkBuilder } from 'walkjs'

const QUERY = (locale, parentRef) => `
*[!(_id in path('drafts.**')) && _type=="page" && site->locale == '${locale}' && _id == '${parentRef}'][0] {
  _id,
  _type,
  title,
  "slug": slug.current,
  "locale": site->locale,
  pageType,
  slices[0] {
    _type == 'trade_landing_hero' => {
      links[] {
        ...,
        page -> {
          _id,
          _type,
          title,
          "slug": slug.current,
          "locale": site->locale,
          pageType,
          "parent": parent->{
            _id,
            _type,
            title,
            "slug": slug.current
          }
        }
      }
    }
  },
  "parent": parent->{
    _id,
    _type,
    title,
    "slug": slug.current
  }
}`

export default async function resolveTradeChildHero (
  input,
  referencesResolver,
  locale
) {
  await new AsyncWalkBuilder()
    .withGlobalFilter((x) => x.val?._type === 'trade_child_hero')
    .withSimpleCallback(async (node) => {
      referencesResolver.addQuery('siblings', QUERY(locale, input.page.parent._id), (key, results) => {
        node.val.siblings = results.slices.links
      })
    })
    .walk(input)
}
