import { AsyncWalkBuilder } from 'walkjs'
import { PAGE_REFERENCE_QUERY } from '../queries'

export const latestPostsQuery = (locale) => `*[!(_id in path('drafts.**')) && _type == 'page' && pageType == 'post' && site->locale == '${locale}'] | order(published_date desc) [0...3] {
  ${PAGE_REFERENCE_QUERY}
}`

export default async function replaceReferences (
  input,
  referencesResolver,
  locale
) {
  await new AsyncWalkBuilder()
    .withGlobalFilter((x) => x.val?._type === 'post_tiles')
    .withSimpleCallback(async (node) => {
      if (node.val.type === 'latest') {
        referencesResolver.addQuery(
          'latest_posts',
          latestPostsQuery(locale),
          (key, results) => {
            node.val.latest = results
          })
      }
    })
    .walk(input)
}
